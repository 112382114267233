.container.register {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  background-color: whitesmoke;

  .register__top {

    p {
      text-align: center;
      font-size: 2rem;
    }

    .top__link {
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .hor__line {
    width: 70vw;
    border: 2px solid gray;
    background-color: gray;
    border-radius: 999px;
    margin: 2rem 0;
  }

  form {
    height: 50vh;
    width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .input__group {
      height: 4rem;
      margin: 1rem 0;
      background-color: white;
      border-radius: 5rem;
      width: 50vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;

      &:nth-child(4) {
        background-color: transparent;
      }

      &.error input {
        border: 2px solid orangered;
      }

      &.weak input {
        border: 2px solid orange;
      }

      &.medium input {
        border: 2px solid yellowgreen;
      }

      &.strong input {
        border: 2px solid green;
      }

      input {
        margin: 1rem 0 1rem 1rem;
        width: 20rem;
        padding: 1rem 0.5rem;
        border-radius: 10px;
        font-size: 1rem;
        border: 2px solid transparent;
        outline: none;
        width: 100%;
        background-color: transparent;

        &[type="submit"] {
          font-family: brandon;
          color: gray;
          font-size: 1.5rem;
          padding: 10px;
          width: 100%;
          border-radius: 20px;
          color: white;
          background-color: black;
          opacity: 0.85;
          cursor: pointer;

          &:disabled {
            background-color: gray;
            color: darkgray;
            cursor: not-allowed;
          }

          &:hover {
            opacity: 1;
          }

          &:active {
            background-color: rgb(36, 36, 36);
          }
        }
      }
    }
  }

  :nth-child(4) {
    font-size: 0.8rem;
  }
}


@media screen and (min-width: 768px) {}

@media screen and (min-width: 1200px) {}